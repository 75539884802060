const Images: string[] = [
  require('./create-new-project.svg'),
  require('./create-new-project-2.svg'),
  require('./create-new-project-3.svg'),
  require('./create-new-project-4.svg'),
  require('./create-new-project-5.svg'),
  require('./create-new-project-6.svg'),
  require('./create-new-project-7.svg'),
  require('./create-new-project-8.svg'),
  require('./create-new-project-9.svg'),
  require('./create-new-project-10.svg'),
  require('./create-new-project-11.svg'),
  require('./create-new-project-12.svg'),
  require('./create-new-project-13.svg'),
  require('./create-new-project-14.svg'),
  require('./create-new-project-15.svg'),
  require('./create-new-project-16.svg'),
];

export default Images;
